.MessageList {
  font-size: small;
}

.ImgEntry {
  align-content: center;
  font-size: small;
}

.TxtEntry {
  align-content: center;
  font-size: small;
}

.ImgEntryImage {
  background-color: black;
}

.Entry {
  background-color: white;
}
.EntryRequest {
  font-size: x-small;
  color: #666666;
  background-color: white;
}
.EntryResponse {
  padding: 10px;
  background-color: white;
}
.EntryCollapse {
  color: red;
  background-color: #666666;
}
.RequestCollapseHeader {
  color: white;
}
