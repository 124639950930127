.HomeScreen {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0px;
  background-color: black;
}

.LocalConversation {
  margin: 0px;
}

.PublicConversation {
  margin: 0px;
}

.PromptSpace {
  background-color: black;
  margin: 1 em;
}
.MainStack {
  width: 550px;
}
.FeedMode {
  font-size: xx-small;
  color: white;
}
.FeedHeader {
  display: flex;
}
.SiteHeader {
  color: white;
  display: flex;
}
.SiteHeaderBranding {
  flex-grow: 1;
}
.ModeSwitch {
  background-color: black;
}
.FeedCard {
  background-color: black;
}
