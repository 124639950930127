.Conversation {
  background-color: grey;
  margin: 0px;
  padding: 0px;
  color: white;
}

.ConversationHeader {
  color: black;
  font-size: small;
  font-weight: bold;
}
.ByLine {
  color: white;
  font-size: xx-small;
}
