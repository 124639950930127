.PrompterSwitch {
  font-size: small;
}
.PrompterSwitchMode {
  font-size: small;
  font-weight: bold;
}
.PrompterInput {
  width: 500px;
  margin: 0px;
  font-size: small;
}
