.SiteHeader {
  background-color: black;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: large;
  color: white;
  margin: 0px;
  text-align: right;
}

.HeaderVersion {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: xx-small;
  color: white;
}
